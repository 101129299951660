.update {
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress {
  height: 12px;
}

.progress progress {
  height: 12px;
  width: 300px;
  display: block;
  appearance: none;
}

.progress progress::-webkit-progress-bar {
  border-radius: 8px;
  background-color: white;
}

.progress progress::-webkit-progress-value {
  border-radius: 8px;
  background: slateblue;
}
